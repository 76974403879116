import React, { useState, useCallback, useEffect } from "react";
import { FaPaypal } from "react-icons/fa6";
import { BiSolidBank } from "react-icons/bi";
import axios from "axios";
import { constants } from "../constants";
import { useNavigate, useLocation } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { IoIosCloseCircle } from "react-icons/io";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import DataHandler from "../dataHandler";

const Withdraw = ({}: any) => {
  const { state } = useLocation();

  const [type, setType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reqloading, setReqLoading] = useState(false);
  const [name, setName] = useState(state?.user?.name ?? "");
  const [phone, setPhone] = useState(state?.user?.phoneNo ?? "");
  const [email, setEmail] = useState(state?.user?.email ?? "");
  const [paypalId, setPaypalId] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [bankAccNumber, setBankAccNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bicNumber, setBicNumber] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [showWithdraw, setShowWithdraw] = useState(false);

  const [bankDetail, setBankDetail] = useState<any>(undefined);
  const navigate = useNavigate();

  const {t}=useTranslation();

  useEffect(() => {
    if (state?.user) {
      fetchBankDetail();
    } else {
      navigate("*");
    }
  }, []);

  const handleBankDetailSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log({
      _id: bankDetail?._id ?? undefined,
      name,
      email,
      phoneNumber: "phone",
      user: state?.user._id,
      paypalId,
      bankName,
      bankAddress,
      accountNo: bankAccNumber,
      accountType,
      swiftOrBICNumber: bicNumber,
    });
    // return;
    try {
      const res = await axios.post(
        `${constants.baseUrl}/account/addUpdateAccount`,
        {
          _id: bankDetail?._id ?? undefined,
          name,
          email,
          phoneNumber: "phone",
          user: state?.user._id,
          paypalId,
          bankName,
          bankAddress,
          accountNo: bankAccNumber,
          accountType,
          swiftOrBICNumber: bicNumber,
        },
        {
          headers: {
            app_id: DataHandler.getInstance()?.userData.app?._id,
          },
        }
      );
      console.log(res.data);
      if (res.data && res.data.isSuccess) {
        // setShowWithdraw(true);
        handleWithdrawReqSubmit(res.data.data._id);
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleWithdrawReqSubmit = async (account:string) => {
    setReqLoading(true);
    console.log({
      account: account,
      user: state?.user._id,
      requstedAmount: withdrawAmount,
    });
    // return;
    try {
      const res = await axios.post(
        `${constants.baseUrl}/withdrawals/requestForWithdrawal`,
        {
          account: account,
          user: state?.user._id,
          requstedAmount: parseInt(state?.user.totalAmountEarned),
        },
        {
          headers: {
            app_id: DataHandler.getInstance()?.userData.app?._id,
          },
        }
      );
      console.log(res.data);
      if (res.data && res.data.isSuccess) {
        // setShowWithdraw(true);
        toast.success(
          t("withdraw_sucess_msg")
        );
        navigate(`/referral/${state.user._id}`);
      }
    } catch (error: any) {
      if (error.response.data.statusCode == 401) {
        
        toast.success(
          t("details_update_msg")
          );
      } else {
        toast.error(
          t("something_went_wrong")
          );
      }
      console.log(error);
    }
    setReqLoading(false);
  };

  const fetchBankDetail = useCallback(async () => {
    try {
      const res = await axios.get(
        `${constants.baseUrl}/account/accounts/${state.user._id}`,
        {
          headers: {
            app_id: DataHandler.getInstance()?.userData.app?._id,
          },
        }
      );
      console.log(res.data);
      if (res.data && res.data.isSuccess && res.data?.data?.length > 0) {
        setBankDetail(res.data.data[0]);
        const account = res.data.data[0];
        setBankAccNumber(account.accountNo);
        setPaypalId(account.paypalId);
        setBankAddress(account.bankAddress);
        setBankName(account.bankName);
        setBicNumber(account.swiftOrBICNumber);
        setAccountType(account.accountType);
        setName(account.name)
        setEmail(account.email)
        setPhone(account.phone)
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  return (
    <div className="w-full flex flex-col items-center pb-[40px]">
      {loading && (
        <div className="fixed top-0 left-0 bg-[rgba(0,0,0,0.4)] z-10 flex justify-center items-center w-full h-full">
          <HashLoader color="#045DE9" />
        </div>
      )}
      {showWithdraw && (
        <div className="absolute top-0 left-0 bg-[rgba(0,0,0,0.4)] z-10 flex justify-center items-start py-10 w-full h-full">
          <div
            className="absolute right-10 top-[50px]"
            onClick={() => setShowWithdraw(false)}
          >
            <IoIosCloseCircle onClick={() => {}} size={22} />
          </div>
          <form
            // onSubmit={handleWithdrawReqSubmit}
            className="w-[90%] rounded-xl h-auto bg-white p-4"
          >
            <p className="text-[15px] text-black text-center uppercase">
              {/* Add withdraw amount */}
              {t("Add_withdraw_amount")}
            </p>
            <input
              onChange={(e) => setWithdrawAmount(e.target.value)}
              placeholder="Enter Amount"
              value={withdrawAmount}
              required
              className="p-3 w-[100%] bg-tertiary my-3 text-[14px] rounded-lg font-poppins"
            />
            <button
              disabled={reqloading}
              className="w-[100%] bg-gradient-to-r from-secondary to-primary cursor-pointer text-center text-white uppercase text-[15px]   py-3 my-3  rounded-full flex justify-center"
            >
              {/* Submit Request */}
              {t("Submit_Request")}
            </button>
          </form>
        </div>
      )}
      <div className="w-full text-center h-[170px] flex justify-center items-center bg-no-repeat bg-[length:100%_170px] bg-top  py-8 px-4 relative topViewMain">
        <FaArrowLeft onClick={() => navigate(-1)} size={22} color="#fff" />
        <p className="text-[18px] text-white uppercase flex flex-1 text-center justify-center items-center">
          {t("Add_Payment_Details")}
        </p>
      </div>
      <div className="w-[90%] my-4 flex justify-around items-center">
        <div className="flex flex-col items-center">
          <button
            // disabled={bankAccNumber.length > 0}
            onClick={() => setType(0)}
            className={`flex justify-center cursor-pointer items-center rounded-lg ${
              type === 0 ? "bg-primary" : "bg-tertiary"
            } w-[60px] h-[60px]`}
          >
            <FaPaypal size={35} color={type === 0 ? "#fff" : "#000"} />
          </button>
          <p className="text-[12px] mt-2 font-medium">{t("Paypal")}</p>
        </div>
        <div className="flex flex-col items-center">
          <button
            // disabled={paypalId.length > 0}
            onClick={() => setType(1)}
            className={`flex justify-center
            cursor-pointer
            ${type === 1 ? "bg-primary" : "bg-tertiary"}
            items-center rounded-lg  w-[60px] h-[60px]`}
          >
            <BiSolidBank size={35} color={type === 1 ? "#fff" : "#000"} />
          </button>
          <p className="text-[12px] mt-2 font-medium">{t("Bank_Details")}</p>
        </div>
      </div>
      <form
        onSubmit={handleBankDetailSubmit}
        className="w-[90%] flex flex-col items-center"
      >
        {type === 0 && (
          <>
            <input
              onChange={(e) => setName(e.target.value)}
              placeholder={`${t("Enter_Your_Name")}*`}
              value={name}
              required
              className="p-3 w-[100%] bg-tertiary my-3 text-[14px] rounded-lg font-poppins"
            />
            {/* <input
            onChange={(e) => {
              // state.user?.email
              //   ? setEmail(e.target.value)
                // :
                //  setPhone(e.target.value);
                setEmail(e.target.value)
            }}
            required
            // value={email ?? phone}
            value={email}
            placeholder={`${t("Enter_Your_Email")}`}              
            className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
          /> */}
          <input
              onChange={(e) => {
                // state.user?.email
                //   ? setEmail(e.target.value)
                  // :
                   setEmail(e.target.value);
              }}
              required
              // value={email ?? phone}
              value={email}
              placeholder={`${t("Enter_Your_Email")}`}              
              className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
            />
            <input
              value={paypalId}
              required
              onChange={(e) => setPaypalId(e.target.value)}
              // placeholder="Enter Your Paypal ID"
              placeholder={`${t("Enter_Your_PayId")}`}
              className="p-3 w-[100%] bg-tertiary my-3 text-[14px] rounded-lg font-poppins"
            />
          </>
        )}

        {/* <p className="">--- OR ---</p> */}
        {type === 1 && (
          <>
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
              placeholder={`${t("Enter_Your_Name")}*`}
              className="p-3 w-[100%] bg-tertiary my-3 text-[14px] rounded-lg font-poppins"
            />
             {/* <input
            onChange={(e) => {
              // state.user?.email
              //   ? setEmail(e.target.value)
                // :
                //  setPhone(e.target.value);
                setEmail(e.target.value)
            }}
            required
            // value={email ?? phone}
            value={email}
            placeholder={`${t("Enter_Your_Email")}`}              
            className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
          /> */}
          <input
              onChange={(e) => {
                // state.user?.email
                //   ? setEmail(e.target.value)
                  // :
                   setEmail(e.target.value);
              }}
              required
              // value={email ?? phone}
              value={email}
              placeholder={`${t("Enter_Your_Email")}`}              
              className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
            />
            
            <input
              onChange={(e) => setBankName(e.target.value)}
              value={bankName}
              required
              
              placeholder={`${t("enter_acc_name")}`}
              className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
            />
            <input
              onChange={(e) => setBankAddress(e.target.value)}
              value={bankAddress}
              required
              
              placeholder={`${t("enter_acc_address")}*`}
              className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
            />
            <input
              value={bankAccNumber}
              required
              onChange={(e) => {
                setBankAccNumber(e.target.value);
              }}
              placeholder={`${t("enter_acc_num")}*`}
              className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
            />
            <select
              className="w-[100%] p-3 my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
              required
              value={accountType}
              onChange={(e) => {
                console.log(e.target.value);

                setAccountType(e.target.value);
              }}
            >
              <option value={"none"}>{t("Account_Type")}</option>
              <option value={"saving"}>{t("Saving")}</option>
              <option value={"current"}>{t("Current")}</option>
            </select>

            <input
              value={bicNumber}
              onChange={(e) => setBicNumber(e.target.value)}              
              placeholder={`${t("enter_acc_ifsc")}`}
              className="p-3 w-[100%] my-3 bg-tertiary text-[14px] rounded-lg font-poppins"
            />
          </>
        )}

        <button
          type="submit"
          className="w-[100%] bg-gradient-to-r from-secondary text-center text-white uppercase text-[15px]  to-primary cursor-pointer  py-3 my-4 mb-6 rounded-full flex justify-center"
        >
          {/* Submit Request */}
          {t("Submit_Request")}
        </button>
      </form>
    </div>
  );
};

export default Withdraw;
