import React from 'react'
import Loader from "react-js-loader";


const LoaderSpinner = () => {
  return (
    <div className={"item"}>
    <Loader type="circual" bgColor={"#045DE9"} color={"white"} size={60} />
</div>
  )
}

export default LoaderSpinner
