import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const userLanguage = window.navigator.language || "hi";
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    hi: {
      translations: require("./locales/hin/translations.json"),
    },
    pt: {
      translations: require("./locales/brazil/translations.json"),
    },
    ru: {
      translations: require("./locales/russia/translations.json"),
    },
    fr: {
      translations: require("./locales/french/translations.json"),
    },
    es: {
      translations: require("./locales/spanish/translations.json"),
    },
    es_mx: {
      translations: require("./locales/spanish-maxico/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "hi", "pt", "ru", "es", "es_mx", "fr"];

export default i18n;
