import React, { Suspense, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Withdraw from "./components/Withdraw";
import i18n from "./i18n/config";
import { useTranslation } from "react-i18next";
import Loader from "./components/Loader";

const MainPage = React.lazy(() => import("./pages/main/Main"));
const Comp404 = React.lazy(() => import("./components/Notfound"));
function App() {
  const { t } = useTranslation();
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const language = queryParameters.get("lang")    
    i18n.changeLanguage(language as string);
  }, []);  
  return (
    <Suspense
      fallback={
        <div className="flex h-full justify-center items-center">
          <Loader/>
        </div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/referral/:userid" element={<MainPage />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="*" element={<Comp404 />} />
        </Routes>
        <Toaster />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
