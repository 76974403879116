class DataHandler {
    static instance: DataHandler | null = new DataHandler();    
    userData: any;    
    static getInstance() {
      if (DataHandler.instance === null) {
        DataHandler.instance = new DataHandler();
      }
      return DataHandler.instance;
    }
  }
  
  export default DataHandler;
  